import React, { useEffect, useState } from 'react'
import Footer from '../superAdmin/footer/Footer'
import Select from "react-select";
import DatePickers from '../../common/DatePickers';
import { message, notification } from "antd";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Table from "react-bootstrap/Table";
import CommonFileUploader from '../../common/CommonFileUploader';
import Tandcform from './Tandcform';
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";

// Function to convert a date string to ISO 8601 format
const convertToISO = (dateStr) => {
  // Parse the date string to a Date object
  const date = new Date(dateStr);
  // Convert to ISO 8601 format with time set to 00:00:00
  return date.toISOString().split('T')[0] + 'T00:00:00';
};

// Function to check if a given date is in the specified range
const isDateInRange = (date) => {
  // Define the start and end dates in ISO format
  const startDate = new Date(getNextDayFormatted(process.env.REACT_APP_START_DATE));
  const endDate = new Date(process.env.REACT_APP_END_DATE);

  // Convert the given date to ISO format and create a Date object
  const givenDate = new Date(convertToISO(date));

  // Perform the date range check
  return givenDate >= startDate && givenDate <= endDate;
};



// const isDateInRange = (date) => {
//   // Define the start and end dates in ISO format
//   const startDate = new Date(getNextDayFormatted(process.env.REACT_APP_START_DATE_FLIGHT));
//   const endDate = new Date(process.env.REACT_APP_END_DATE_FLIGHT);

//   // Convert the given date to ISO format and create a Date object
//   const givenDate = new Date(convertToISO(date));

//   // Perform the date range check
//   return givenDate >= startDate && givenDate <= endDate;
// };

function getNextDayFormatted(date) {
  return moment(date).add(1, 'days').format('lll');
}
// Example usage
const inputDate = "Nov 14, 2024";
const isValid = isDateInRange(inputDate);

// console.log(isValid); // Output: false (since Nov 14, 2024 is not in the range)


const BuyerRegistrationForm = ({ phoneNumber }) => {
  const company_category_array = [
    "Retailer",
    "Distributor",
    "Wholesaler"
  ]
  const designationArray = [
    "Director",
    "Partner",
    "Staff"];
  const [representative_list, setRepresentative_list] = useState([
    "Akhil",
    "Kapil Ladiwal",
    "Nisha",
    "Arpan",
    "Arpan",
    "Venkat",
    "Aarpan",
    "Abhishek Jain",
    "Sanjay",
    "Mr.Venkatasayee",
    "Venkatasayee. B",
    "Tanmay Das",
    "Sonu Vats",
    "T Das",
    "Pradnya H",
    "Kapil Ji",
    "Shrey Kamdar",
    "Bhavin Z",
    "Mr. T. Das",
    "Venkatesh Iyer",
    "Kapil",
    "Karan",
    "Akil Aravind",
    "Venkatasayee Balasunderam",
    "Sonu",
    "Venkatasayee",
    "Nitin",
    "Arjun Mistry",
    "Mr.Venkat",
    "Umesh",
    "Meenakshi Jain",
    "Akhil Aravind",
    "Akhl"
  ])
  const initialFormData = {
    representative: '',
    companyName: '',
    roomNo: '',
    title: '',
    personName: '',
    mobileNumber: '',
    emailAddress: '',
    designation: '',
    country: '',
    alloted_car: '',
    roomOccupancy: '',
    ticketsDepartureBookedBy: '',
    ticketsReturnBookedBy: '',
    ticketsDepartureBookedBy2nd: '',
    ticketsReturnBookedBy2nd: '',
    travelMode: '',
    travelMode2nd: '',
    travelReturnMode: '',
    travelReturnMode2nd: '',
    departurePNR: '',
    otherDesignation: '',
    departureCity: '',
    departureDate: '',
    flightTrainName: '',
    departure_time_from_source: '',
    flightTrainNumber: '',
    arrivalTimeGoa: '',
    goaAirport: '',
    returnPNR: '',
    returnCity: '',
    returnFlightDate: '',
    returnFlightName: '',
    returnFlightNumber: '',
    returnFlightDepartureTime: '',
    returnAirport: '',
    title_2nd: '',
    personName2nd: '',
    mobileNumber2nd: '',
    emailAddress2nd: '',
    designation2nd: '',
    country2nd: '',
    alloted_car2nd: '',
    roomOccupancy2nd: '',
    departurePNR2nd: '',
    otherDesignation2nd: '',
    departureCity2nd: '',
    departureDate2nd: '',
    flightTrainName2nd: '',
    flightTrainNumber2nd: '',
    arrivalTimeGoa2nd: '',
    goaAirport2nd: '',
    returnPNR2nd: '',
    returnCity2nd: '',
    returnFlightDate2nd: '',
    departure_time_from_source2nd: '',

    returnFlightName2nd: '',
    returnFlightNumber2nd: '',
    returnFlightDepartureTime2nd: '',
    returnAirport2nd: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [hotelCheckInDate, setHotelCheckInDate] = useState(null)
  const [hotelCheckOutDate, setHotelCheckOutDate] = useState(null)
  const [departureDate, setDepartureDate] = useState(null)
  const [returnFlightDate, setReturnFlightDate] = useState(null)
  const [hotelCheckInDate2nd, setHotelCheckInDate2nd] = useState(null)
  const [hotelCheckOutDate2nd, setHotelCheckOutDate2nd] = useState(null)
  const [departureDate2nd, setDepartureDate2nd] = useState(null)
  const [returnFlightDate2nd, setReturnFlightDate2nd] = useState(null)
  const urlPath = window.location.pathname; // This will return '/buyer-registration-form'
  const extractedPart = urlPath.split('/').pop(); // This will return 'buyer-registration-form'
  const [travelModerarrivalArray2nd, setTravelModerarrivalArray2nd] = useState(["Train", "Flight", "Self"])
  const [travelModerarrivalArray, setTravelModerarrivalArray] = useState(["Train", "Flight", "Self"])
  const [travelModerDepartureArray, setTravelModerDepartureArray] = useState(["Train", "Flight", "Self"])
  const [travelModerDepartureArray2nd, setTravelModerDepartureArray2nd] = useState(["Train", "Flight", "Self"])
  const [isChecked1st, setIsChecked1st] = useState(false);
  const [isChecked2nd, setIsChecked2nd] = useState(true);
  const [isChecked3rd, setIsChecked3rd] = useState(true);
  const [showBlock2nd, setShowBlock2nd] = useState(false)
  const [showBlock3rd, setShowBlock3rd] = useState(false)
  const [showBlock4th, setShowBlock4th] = useState(false)
  const [showBlock5th, setShowBlock5th] = useState(false)
  const [showBlock6th, setShowBlock6th] = useState(false)
  const [gst_number, setGst_number] = useState(null)
  const [company_city, setCompany_city] = useState(null)
  const [company_state, setCompany_state] = useState(null)
  const [company_address, setCompany_address] = useState(null)
  const [company_pincode, setCompany_pincode] = useState(null)
  const navigate = useNavigate()
  const [gst_data_present, setGst_data_present] = useState(null)
  const [fristMobileNumberError, setFristMobileNumberError] = useState(null)
  const [secondMobileNumberError, setSecondMobileNumberError] = useState(null)
  const [no_of_stores, set_no_of_stores] = useState(null)
  const [sqft_area, set_sqft_area] = useState(null)
  const [no_of_staff, set_no_of_staff] = useState(null)
  const [company_category, set_company_category] = useState(null)
  const [photo, setPhoto] = useState();
  const [idProofFront, setIDProofFront] = useState();
  const [idProofBack, setIDProofBack] = useState();
  const [second_photo, set_second_photo] = useState();
  const [second_idProofFront, set_second_idProofFront] = useState();
  const [second_idProofBack, set_second_idProofBack] = useState();
  const [duplicateError, setDuplicateError] = useState(null);
  const [error_1st, set_error_1st] = useState(false);
  const [error_2nd, set_error_2nd] = useState(null);
  const [error_3rd, set_error_3rd] = useState(null);
  const [error_4th, set_error_4th] = useState(null);
  const [error_5th, set_error_5th] = useState(null);
  const [error_6th, set_error_6th] = useState(null);
  const [error_7th, set_error_7th] = useState(null);
  const [error_8th, set_error_8th] = useState(null);
  const [error_9th, set_error_9th] = useState(null);
  const [error_10th, set_error_10th] = useState(null);
  const [error_11th, set_error_11th] = useState(null);
  const [error_12th, set_error_12th] = useState(null);
  const [error_13th, set_error_13th] = useState(null);
  const [loader, setLoader] = useState(false)
  const [isChecked, setIsChecked] = useState(true)
  const [categories, setCategories] = useState([
    { id: 1, name: 'Gold', percentage: '' },
    { id: 2, name: 'Diamond', percentage: '' },
    { id: 3, name: 'Others', percentage: '' },
  ]);
  const [error, setError] = useState('');
  const [loadingState, setloadingState] = useState(false);
  const [memberListData, setMemberListData] = useState([]);
  const [memberTicketBookedList, setMemberTicketBookedList] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [imageValue, setImageValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showError, setShowError] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [bookingData, setBookingData] = useState(null);
  const [showEditBookingModal, setShowEditBookingModal] = useState(false);
  const [bookingEditData, setBookingEditData] = useState(null);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [editProfileData, setEditProfileData] = useState(null);

  const [editVisitorData, setEditVisitorData] = useState(null);
  const [editFormData, setEditFormData] = useState({
    travelMode: '',
    travelReturnMode: '',
    departureCity: '',
    returnCity: '',
    departureDate: null,
    returnFlightDate: null,
    flightTrainName: '',
    returnFlightName: '',
    flightTrainNumber: '',
    returnFlightNumber: '',
    arrivalTimeGoa: '',
    departure_time_from_source: '',
    returnFlightDepartureTime: '',
  });
  const [editDepartureDate, setEditDepartureDate] = useState(null);
  const [editReturnFlightDate, setEditReturnFlightDate] = useState(null);

  const regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  function checkRegex(input) {
    return regex.test(input);
  }

  function convertToUpperCase(input) {
    return input.replace(/[a-z]/gi, (char) => char.toUpperCase());
  }

  const handleGstNumberChange = (e) => {
    setGst_number(convertToUpperCase(e.target.value));
    const value = e.target.value;
    if (checkRegex(value)) {
      // console.log("asdasdasd", e.target.value);
      setShowError("");
    } else {
      setShowError("Provide Valid GSTIN");
    }
  };

  // console.log(extractedPart);
  const handleSelectChange = (selectedOption, object) => {
    const selectedValue = selectedOption ? selectedOption.value : null;
    if (object === "2nd") {
      if (selectedValue !== "others") {
        setFormData({ ...formData, designation2nd: selectedValue, otherDesignation2nd: "" });
      } else {
        setFormData({ ...formData, designation2nd: "others" });
      }
    } else {

      if (selectedValue !== "others") {
        setFormData({ ...formData, designation: selectedValue, otherDesignation: "" });
      } else {
        setFormData({ ...formData, designation: "others" });
      }
    }
  };

  const handleOtherDesignationChange = (event, object) => {
    const value = event.target.value;
    if (object === "2nd") {
      setFormData({ ...formData, otherDesignation2nd: value });

    } else {

      setFormData({ ...formData, otherDesignation: value });
    }
  };
  // Create a mapping object for field names to display names
  const fieldDisplayNames = {
    representative: 'Representative',
    companyName: 'Company Name',
    roomNo: 'Room Number',
    roomOccupancy: 'Room Occupancy',
    personName: 'Person Name',
    emailAddress: 'Email Address',
    mobileNumber: 'Mobile Number',
    designation: 'Designation',
    hotelCheckInDate: 'Hotel Check-In Date',
    hotelCheckOutDate: 'Hotel Check-Out Date',
    ticketsBookedBy: 'Tickets Booked By',
    travelMode: 'Travel Mode',
    travelReturnMode: 'Return Travel Mode',

    departurePNR: 'Departure PNR',
    departureCity: 'Departure City',
    departureDate: 'Departure Date',
    flightTrainName: 'Flight/Train Name',
    flightTrainNumber: 'Flight/Train Number',
    arrivalTimeGoa: 'Arrival Time in Goa',
    goaAirport: 'Goa Airport',
    returnPNR: 'Return PNR',
    returnCity: 'Return City',
    returnFlightDate: 'Return Flight Date',
    returnFlightName: 'Return Flight Name',
    returnFlightNumber: 'Return Flight Number',
    returnFlightDepartureTime: 'Return Flight Departure Time',
    returnAirport: 'Return Airport of 2nd person',
    personName2nd: 'Person Name of 2nd person',
    emailAddress2nd: 'Email Address of 2nd person',
    mobileNumber2nd: 'Mobile Number of 2nd person',
    designation2nd: 'Designation of 2nd person',
    hotelCheckInDate2nd: 'Hotel Check-In Date of 2nd person',
    hotelCheckOutDate2nd: 'Hotel Check-Out Date of 2nd person',
    ticketsBookedBy2nd: 'Tickets Booked By of 2nd person',
    travelMode2nd: 'Travel Mode of 2nd person',
    travelReturnMode2nd: 'Return Travel Mode 2nd person',

    departurePNR2nd: 'Departure PNR of 2nd person',
    departureCity2nd: 'Departure City of 2nd person',
    departureDate2nd: 'Departure Date of 2nd person',
    flightTrainName2nd: 'Flight/Train Name of 2nd person',
    flightTrainNumber2nd: 'Flight/Train Number of 2nd person',
    arrivalTimeGoa2nd: 'Arrival Time in Goa of 2nd person',
    goaAirport2nd: 'Goa Airport of 2nd person',
    returnPNR2nd: 'Return PNR of 2nd person',
    returnCity2nd: 'Return City of 2nd person',
    returnFlightDate2nd: 'Return Flight Date of 2nd person',
    returnFlightName2nd: 'Return Flight Name of 2nd person',
    returnFlightNumber2nd: 'Return Flight Number of 2nd person',
    returnFlightDepartureTime2nd: 'Return Flight Departure Time of 2nd person',
    returnAirport2nd: 'Return Airport of 2nd person'

  };

  const ticket_by = ["IGI", "Self"]
  const occupancy_list = ["Single", "Double"]
  const airportListOfGoa = ["Dabolim Airport and Manohar International Airport", "Mopa Airport"]
  const titleData = ["Mr.", "Ms."]
  // Array of required fields
  const requiredFields = [
    'representative',
    'companyName',
    'personName',
    'mobileNumber',
    'designation',
    'travelMode',
    'roomOccupancy',
    // 'departurePNR',
    // 'departureCity',
    // 'flightTrainName',
    // 'flightTrainNumber',
    // 'arrivalTimeGoa',
    // 'goaAirport',
    // 'returnPNR',
    // 'returnCity',
    // 'returnFlightName',
    // 'returnFlightNumber',
    // 'returnFlightDepartureTime',
    // 'returnAirport',


  ];

  const requiredforSecondFields = [
    'personName2nd',
    'mobileNumber2nd',
    'designation2nd',
    // 'departurePNR2nd',
    // 'departureCity2nd',
    // 'flightTrainName2nd',
    // 'flightTrainNumber2nd',
    // 'arrivalTimeGoa2nd',
    // 'goaAirport2nd',
    // 'returnPNR2nd',
    // 'returnCity2nd',
    // 'returnFlightName2nd',
    // 'returnFlightNumber2nd',
    // 'returnFlightDepartureTime2nd',
    // 'returnAirport2nd',


  ];

  // Validation function to check if all required fields are filled
  const validateForm = () => {
    let isValid = true;
    let missingFields = [];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        isValid = false;
        missingFields.push(fieldDisplayNames[field]);  // Use display names
      }
    });

    return { isValid, missingFields };
  };


  const validate2ndForm = () => {
    let isValid2nd = true;
    let missingFields2nd = [];
    if (isChecked1st) {

      requiredforSecondFields.forEach((field) => {
        if (!formData[field]) {
          isValid2nd = false;
          missingFields2nd.push(fieldDisplayNames[field]);  // Use display names
        }
      });
    }

    return { isValid2nd, missingFields2nd };
  };


  const validateEmail = (email) => {
    // Simple email regex for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  const handleChange = (e, data = false) => {
    const { name, value } = e.target;
    if (name === 'companyName') {
      // Capitalize the first letter of each word for companyName
      const uppercaseValue = value.toUpperCase();
      if (data) {
        setEditFormData(prevData => ({ ...prevData, [name]: uppercaseValue }));
      } else {
        setFormData(prevData => ({ ...prevData, [name]: uppercaseValue }));
      }
    } else if (['representative', 'companyName', 'personName', 'personName2nd'].includes(name)) {
      // Ensure only alphabetic characters for these fields
      const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, '');
      if (data) {
        setEditFormData(prevData => ({ ...prevData, [name]: alphabeticValue }));
      } else {
        setFormData(prevData => ({ ...prevData, [name]: alphabeticValue }));
      }
    }

    else if (name === 'mobileNumber') {
      // Ensure only numeric characters and length is 10
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
      }));
    } else if (name === 'mobileNumber2nd') {
      // Ensure only numeric characters and length is 10
      const numericValue = value.replace(/[^0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: numericValue.length <= 10 ? numericValue : numericValue.slice(0, 10)
      }));
    }

    else {
      // For other fields, just update the state as usual
      if (data) {
        setEditFormData(prevData => ({ ...prevData, [name]: value }));
      } else {
        setFormData(prevData => ({ ...prevData, [name]: value }));
      }
    }
  };

  useEffect(() => {
    if (formData.mobileNumber && formData.mobileNumber.length === 10) {

      axios.get(`${process.env.REACT_APP_API_END_POINT}/check-number-is-present/${formData.mobileNumber}`).then((resData) => {

        setFristMobileNumberError(resData.data)
      }).catch((err) => {
        setFristMobileNumberError(false)

      })
    }

  }, [
    formData.mobileNumber
  ])

  useEffect(() => {
    if (formData.mobileNumber2nd && formData.mobileNumber2nd.length === 10) {

      axios.get(`${process.env.REACT_APP_API_END_POINT}/check-number-is-present/${formData.mobileNumber2nd}`).then((resData) => {
        setSecondMobileNumberError(resData.data)

      }).catch((err) => {
        setSecondMobileNumberError(err.response.data)

      })
    }

  }, [
    formData.mobileNumber2nd
  ])


  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value) {

      if (name === 'emailAddress' && !validateEmail(value)) {
        alert('This is not a correct email address');
        setFormData(prevData => ({ ...prevData, [name]: '' })); // Optionally clear the field
      }
      if (name === 'emailAddress2nd' && !validateEmail(value)) {
        alert('This is not a correct email address');
        setFormData(prevData => ({ ...prevData, ["emailAddress2nd"]: '' })); // Optionally clear the field
      }
    }
  };


  useEffect(() => {
    // console.log("hello i am here to see the test daeeed", gst_number?.length);

    if (gst_number?.length === 15 && checkRegex(gst_number)) {
      // setReloadShow(true);
      // ;

      axios
        .get(
          `${process.env.REACT_APP_API_END_POINT}/check-gst-validation/${gst_number}`
        )
        .then((resData) => {
          // setReloadShow(false);
          // console.log(
          //   "hello i am here to see the test daeeed",
          //   resData.data.message,
          //   resData.data.data.lgnm,
          //   resData.data.data.pradr.adr
          // );
          // if (resData.data.message === "GSTIN  found.") {
          // console.log("gst find or not", resData.data);
          if (resData.data.flag === false) {
            setShowError(resData.data.message);
            return;
          }

          setShowError("");
          setGst_data_present(resData.data)
          setFormData({ ...formData, companyName: resData.data.company });

          setCompany_address(resData.data.address);
          setCompany_city(resData.data.city);
          setCompany_state(resData.data.state);
          setCompany_pincode(resData.data.pincode);
          // }
        });
    }
  }, [gst_number]);


  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.mobileNumber.length !== 10) {
      message.error(`Enter 10 digit Number`)
      return;
    }

    if (fristMobileNumberError) {
      message.error(`Number already taken`)
      return;
    }

    setFormData({
      ...formData, designation: formData.designation === "others"
        ? formData.otherDesignation
        : formData.designation
    });

    // if (formData.roomOccupancy === "Double") {
    //   setFormData({
    //     ...formData, designation2nd: formData.designation2nd === "others"
    //       ? formData.otherDesignation2nd
    //       : formData.designation2nd
    //   });
    // }

    // if (!isChecked) {
    //   return notification.error({
    //     message: "Accept the guidelines",
    //     description: "",
    //   });
    // }

    // if (isChecked1st && formData?.mobileNumber2nd?.length !== 10) {
    //   console.log("2nd number", formData?.mobileNumber2nd?.length)

    //   return message.error(`Enter 10 Digit Number`)
    // }

    // { error_1st && <span style={{ color: 'red' }}>{dateValidation}</span> }

    // setLoader(true)

    // if (!isDateInRange(hotelCheckInDate) || !isDateInRange(hotelCheckOutDate)) {
    //   setLoader(false)

    //   message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`);
    // }
    // else if ((!isDateInRange(departureDate))) {
    //   message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} Arrival date`);

    //   setLoader(false)

    // }
    // else if ((isChecked1st && !isChecked2nd) && !isDateInRange(departureDate2nd)) {
    //   message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} Arrival date of second person`);
    //   setLoader(false)
    // }
    // else if (!isDateInRange(returnFlightDate)) {
    //   message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} return date`);
    //   setLoader(false)
    // }
    // else if ((isChecked1st && !isChecked3rd) && !isDateInRange(returnFlightDate2nd)) {
    //   message.error(`Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')} return date of second person`);

    //   setLoader(false)

    // }
    // else {
      const dataSend = new FormData();
      for (const key in formData) {
        if (formData[key] === undefined || formData[key] === null) {
          formData[key] = ''
        }

        if (formData.hasOwnProperty(key)) {
          dataSend.append(key, formData[key]);
        }
      }

      // if (departureDate) {
      //   dataSend.append("departureDate", departureDate);
      // } else {
      //   console.log("departureDate is not provided.");
      // }

      // if (returnFlightDate) {
      //   dataSend.append("returnFlightDate", returnFlightDate);
      // } else {
      //   console.log("returnFlightDate is not provided.");
      // }

      // if (departureDate2nd) {
      //   dataSend.append("departureDate2nd", departureDate2nd);
      // } else {
      //   console.log("departureDate2nd is not provided.");
      // }

      // if (returnFlightDate2nd) {
      //   dataSend.append("returnFlightDate2nd", returnFlightDate2nd);
      // } else {
      //   console.log("returnFlightDate2nd is not provided.");
      // }

      // if (hotelCheckInDate) {
      //   dataSend.append("hotelCheckInDate", hotelCheckInDate);
      // } else {
      //   dataSend.append("hotelCheckInDate", "");
      // }

      // if (hotelCheckOutDate) {
      //   dataSend.append("hotelCheckOutDate", hotelCheckOutDate);
      // } else {
      //   dataSend.append("hotelCheckOutDate", "");
      // }

      // if (hotelCheckInDate2nd) {
      //   dataSend.append("hotelCheckInDate2nd", hotelCheckInDate2nd);
      // } else {
      //   dataSend.append("hotelCheckInDate2nd", "");
      // }

      // if (hotelCheckOutDate2nd) {
      //   dataSend.append("hotelCheckOutDate2nd", hotelCheckOutDate2nd);
      // } else {
      //   dataSend.append("hotelCheckOutDate2nd", "");
      // }

      // dataSend.append("isChecked1st", isChecked1st || "")
      // dataSend.append("isChecked2nd", isChecked2nd || "")
      // dataSend.append("isChecked3rd", isChecked3rd || "")

      dataSend.append("gst_number", gst_number || "")
      dataSend.append("company_address", company_address || "")
      dataSend.append("company_city", company_city || "")
      dataSend.append("company_state", company_state || "")
      dataSend.append("company_pincode", company_pincode || "")
      // dataSend.append("no_of_stores", no_of_stores || "")
      // dataSend.append("sqft_area", sqft_area || "")
      // dataSend.append("no_of_staff", no_of_staff || "")
      dataSend.append("categories", JSON.stringify(categories))
      dataSend.append("other_category", JSON.stringify(other_category))

      dataSend.append("photo", photo)
      dataSend.append("id_proof_front", idProofFront)
      dataSend.append("id_proof_back", idProofBack)
      // dataSend.append("second_photo", second_photo)
      // dataSend.append("second_id_proof_front", second_idProofFront)
      // dataSend.append("second_id_proof_back", second_idProofBack)
      dataSend.append("isChecked", isChecked)

      axios.post(`${process.env.REACT_APP_API_END_POINT}/retailers-detail`, dataSend)
        .then((resData) => {
          // localStorage.removeItem('barcode')
          setLoader(false)
          // localStorage.removeItem('password_form')
          // localStorage.removeItem('from_status')
          // navigate("/thank-you")
          notification.success({
            message: "Form Submitted Successfully",
            description: "",
          });

          window.location.reload(true);
        })
        .catch((error) => {
          console.error("Error occurred while submitting the form:", error);
          alert('An error occurred...');
        });
    // }
  };

  const updateGSTDetails = (e) => {
    e.preventDefault();

    if (!gst_number) {
      message.error('Please enter GST number');
      return;
    }

    if (showError === '') {
      axios.post(`${process.env.REACT_APP_API_END_POINT}/update-gst-details`, {
        phoneNumber: localStorage.getItem('mobile_number'),
        gst_number: gst_number,
        company_name: formData.companyName,
        company_address,
        company_city,
        company_state,
        company_pincode,
      })
        .then((resData) => {
          setShowUpdateButton(true);
          window.location.reload(true);
        })
        .catch((error) => {
          console.error("Error occurred while submitting the form:", error);
          alert('An error occurred...');
        });
    } else {
      alert('Please enter valid GST Number.')
    }
  }

  useEffect(() => {
    if (hotelCheckInDate) {
      if (!isDateInRange(hotelCheckInDate)) {
        set_error_5th(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_5th(false)

      }
    }
  }, [hotelCheckInDate]);

  useEffect(() => {
    if (hotelCheckOutDate) {
      if (!isDateInRange(hotelCheckOutDate)) {
        set_error_6th(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_6th(false)

      }
    }
  }, [hotelCheckOutDate]);


  useEffect(() => {
    if (departureDate) {
      if (!isDateInRange(departureDate)) {
        set_error_1st(true)

        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_1st(false)

      }
    }
  }, [departureDate]);

  useEffect(() => {
    if (editDepartureDate) {
      if (!isDateInRange(editDepartureDate)) {
        set_error_12th(true)

        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_12th(false)

      }
    }
  }, [editDepartureDate]);

  useEffect(() => {
    if (returnFlightDate) {
      if (!isDateInRange(returnFlightDate)) {
        set_error_3rd(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_3rd(false)

      }
    }
  }, [returnFlightDate]);

  useEffect(() => {
    if (editReturnFlightDate) {
      if (!isDateInRange(editReturnFlightDate)) {
        set_error_13th(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_13th(false);

      }
    }
  }, [editReturnFlightDate]);

  useEffect(() => {
    if (hotelCheckInDate2nd) {
      if (!isDateInRange(hotelCheckInDate2nd)) {
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,
          description: "",
        });
      }
    }
  }, [hotelCheckInDate2nd]);

  useEffect(() => {
    if (hotelCheckOutDate2nd) {
      if (!isDateInRange(hotelCheckOutDate2nd)) {
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      }
    }
  }, [hotelCheckOutDate2nd]);


  useEffect(() => {
    if (departureDate2nd) {
      if (!isDateInRange(departureDate2nd)) {
        set_error_2nd(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,

          description: "",
        });
      } else {
        set_error_2nd(false)

      }
    }
  }, [departureDate2nd]);

  useEffect(() => {
    if (returnFlightDate2nd) {
      if (!isDateInRange(returnFlightDate2nd)) {
        set_error_4th(true)
        notification.error({
          message: `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`,
          description: "",
        });
      } else {
        set_error_4th(false)
      }
    }
  }, [returnFlightDate2nd]);

  useEffect(() => {
    if (formData.ticketsDepartureBookedBy === "IGI") {

      setTravelModerarrivalArray(["Train", "Flight"])
    } else {
      setTravelModerarrivalArray(["Train", "Flight", "Self"])

    }
  }, [formData.ticketsDepartureBookedBy])

  useEffect(() => {
    if (formData.ticketsReturnBookedBy === "IGI") {

      setTravelModerDepartureArray(["Train", "Flight"])
    } else {

      setTravelModerDepartureArray(["Train", "Flight", "Self"])
    }
  }, [formData.ticketsReturnBookedBy])

  useEffect(() => {
    if (formData.ticketsDepartureBookedBy2nd === "IGI") {
      setTravelModerarrivalArray2nd(["Train", "Flight"])

    } else {
      setTravelModerarrivalArray2nd(["Train", "Flight", "Self"])

    }
  }, [formData.ticketsDepartureBookedBy2nd])

  useEffect(() => {
    if (formData.ticketsReturnBookedBy2nd === "IGI") {
      setTravelModerDepartureArray2nd(["Train", "Flight"])
    } else {
      setTravelModerDepartureArray2nd(["Train", "Flight", "Self"])
    }
  }, [formData.ticketsReturnBookedBy2nd])

  useEffect(() => {
    if (extractedPart === `gcc-retailer-registration-form`) {
      setRepresentative_list(['Sadiye'])
    }

    const phoneNumber = localStorage.getItem('mobile_number');
    getBuyerDetails(phoneNumber);
  }, [])

  const getBuyerDetails = async (phoneNumber) => {
    try {
      const details = await axios.get(`${process.env.REACT_APP_API_END_POINT}/get-buyer-details/${phoneNumber}`);
      const data = details.data.data;

      if (!data.length) {
        setloadingState(true);
        return;
      }

      setGst_number(data[0].gst_number);
      setShowUpdateButton(true);
      setMemberListData(data);

      const filteredData = data.filter((item) => item.travelMode === "" || item.travelMode === null || item.travelMode === undefined);
      setMemberTicketBookedList(filteredData);
      setloadingState(true);
    } catch (error) {
      console.error('Error: ', error);
      alert('Error occurred');
    }
  }

  const handleCheckboxChange = (data, setData) => {
    setData(!data);
  };

  useEffect(() => {
    if (isChecked1st) {
      setIsChecked2nd(true)
      setIsChecked3rd(true)
    }
  }, [isChecked1st])

  useEffect(() => {
    if (formData.roomOccupancy === "Single") {
      setIsChecked1st(false)
      setIsChecked2nd(true)
      setIsChecked3rd(true)
      setShowBlock3rd(false)
      setShowBlock4th(false)

    }
  }, [formData.roomOccupancy])

  // Handle input change for percentage
  const handlePercentageChange = (id, value) => {
    const updatedCategories = categories.map((category) =>
      category.id === id ? { ...category, percentage: value } : category
    );

    setCategories(updatedCategories);
    validatePercentages(updatedCategories);
  };

  // Validate if the sum of percentages equals 100
  const validatePercentages = (updatedCategories) => {
    const totalPercentage = updatedCategories.reduce((sum, category) => {
      return sum + (parseFloat(category.percentage) || 0);
    }, 0);

    if (totalPercentage !== 100) {
      setError('The total percentage must equal 100.');
    } else {
      setError('');
    }
  };

  const calculateRemainingPercentage = (categories) => {
    const totalPercentage = categories.reduce((sum, category) => {
      return sum + (parseFloat(category.percentage) || 0);
    }, 0);

    const remainingPercentage = 100 - totalPercentage;

    return remainingPercentage > 0 ? remainingPercentage : 0;
  };

  const [other_category, setOtherCategory] = useState([
    { id: 1, key: 'Antique Gold Jewellery', value: '' },
    { id: 2, key: 'Couture Jewellery', value: '' },
    { id: 3, key: 'Diamond Jewellery', value: '' },
    { id: 4, key: 'Generic Gold Jewellery', value: '' },
    { id: 6, key: 'Allied/Loose Stone/Lab Grown', value: '' },
  ]);

  const [other_error, setOtherError] = useState('');

  const handlePriorityChange = (index, value) => {
    const numValue = parseInt(value, 10);
    const updatedCategories = other_category.map((category, i) =>
      i === index ? { ...category, value: numValue } : category
    );

    setOtherCategory(updatedCategories);
    validatePriorities(updatedCategories);
  };

  const validatePriorities = (updatedCategories) => {
    const assignedPriorities = updatedCategories
      .map((category) => parseInt(category.value, 10))
      .filter((value) => !isNaN(value));

    const uniquePriorities = new Set(assignedPriorities);
    const maxPriority = Math.max(...Array.from(uniquePriorities), 0);

    // Error messages
    let errorMessage = '';

    // Case 1: Max priority should not be less than 4
    if (maxPriority < 4) {
      errorMessage = 'The maximum priority should be at least 4.';
    }
    // Case 2: Ensure all numbers from 1 to maxPriority are present
    else if (Array.from({ length: maxPriority }, (_, i) => i + 1).some(num => !uniquePriorities.has(num))) {
      errorMessage = `All priorities from 1 to ${maxPriority} must be filled sequentially.`;
    }
    // Case 3: Check for unique priorities
    else if (uniquePriorities.size !== assignedPriorities.length) {
      errorMessage = 'Each priority must be unique.';
    }

    // Set the error message
    if (errorMessage) {
      setOtherError(errorMessage);
    } else {
      setOtherError('');
    }
  };

  // const addCategory = () => {
  //   if (other_category.length < 4) {
  //     setOtherCategory([
  //       ...other_category,
  //       { key: `New Category ${other_category.length + 1}`, value: other_category.length },
  //     ]);
  //   }
  // };

  function handlePhoto(e) {
    setPhoto(e.target.files[0]);
  }
  function handleIDFront(e) {
    setIDProofFront(e.target.files[0]);
  }
  function handleIDBack(e) {
    setIDProofBack(e.target.files[0]);
  }

  const handleDeleteBuyer = (e) => {
    e.preventDefault();

    const mobileNumber = localStorage.getItem('mobile_number');

    try {
      axios.post(`${process.env.REACT_APP_API_END_POINT}/shift-visitor-to-old-visitor`, { visitor_id: deleteData._id})
        .then((resData) => {
          console.log('Hello there');
        }).catch((err) => {
          console.error('An error occurred: ', err);
        })

      if (mobileNumber == deleteData.mobile_number) {
        localStorage.removeItem('mobile_number');
        localStorage.removeItem('barcode');
        localStorage.removeItem('from_status');
        localStorage.removeItem('password_form');
      }
      setShowDeleteModal(false);
      window.location.reload(true);
    } catch (error) {
      console.error('Error occurred while deleting buyer: ', error);
    }
  }

  const saveFlightDetails = (e) => {
    e.preventDefault();

    const requestData = {
      travelMode: formData.travelMode || '',
      travelReturnMode: formData.travelReturnMode || '',
      departureCity: formData.departureCity || '',
      returnCity: formData.returnCity || '',
      departureDate: departureDate || '',
      returnFlightDate: returnFlightDate || '',
      flightTrainName: formData.flightTrainName || '',
      returnFlightName: formData.returnFlightName || '',
      flightTrainNumber: formData.flightTrainNumber || '',
      returnFlightNumber: formData.returnFlightNumber || '',
      arrivalTimeGoa: formData.arrivalTimeGoa || '',
      departure_time_from_source: formData.departure_time_from_source || '',
      returnFlightDepartureTime: formData.returnFlightDepartureTime || '',
    }

    try {
      for (const data of selectedMembers) {
        axios.post(`${process.env.REACT_APP_API_END_POINT}/set-flight-details`, { visitorId: data._id, data: requestData})
          .then((resData) => {
            // console.log('Response data: ', data._id, resData);
          }).catch((err) => {
            console.error('An error occurred: ', err);
          });
      }

      setFormData({
        ...formData,
        travelMode: '',
        travelReturnMode: '',
        departureCity: '',
        flightTrainName: '',
        returnFlightName: '',
        flightTrainNumber: '',
        returnFlightNumber: '',
        arrivalTimeGoa: '',
        departure_time_from_source: '',
        returnFlightDepartureTime: '',
      });

      setDepartureDate(null);
      setReturnFlightDate(null);

      setTimeout(() => {
        setShowBookingModal(false);
        window.location.reload(true);
      }, 1000)
    } catch (error) {
      console.error('Error occurred while deleting buyer: ', error);
    }
  }

  const handleEditSubmit = (e) => {
    e.preventDefault();

    setFormData({
      ...formData, designation: formData.designation === "others"
        ? formData.otherDesignation
        : formData.designation
    });

      const dataSend = new FormData();
      for (const key in formData) {
        if (formData[key] === undefined || formData[key] === null) {
          formData[key] = ''
        }

        if (formData.hasOwnProperty(key)) {
          dataSend.append(key, formData[key]);
        }
      }

      dataSend.append("photo", photo)
      dataSend.append("id_proof_front", idProofFront)
      dataSend.append("id_proof_back", idProofBack)

      console.log('Data send: ', JSON.stringify(dataSend));

      axios.post(`${process.env.REACT_APP_API_END_POINT}/edit-retailers-detail`, dataSend)
        .then((resData) => {
          setLoader(false);
          notification.success({
            message: "Buyer edited successfully",
            description: "",
          });

          window.location.reload(true);
        })
        .catch((error) => {
          console.error("Error occurred while submitting the form:", error);
          alert('An error occurred...');
        });
  };

  const editFlightDetails = (e) => {
    e.preventDefault();

    const requestData = {
      travelMode: editFormData.travelMode || '',
      travelReturnMode: editFormData.travelReturnMode || '',
      departureCity: editFormData.departureCity || '',
      returnCity: editFormData.returnCity || '',
      departureDate: editDepartureDate || '',
      returnFlightDate: editReturnFlightDate || '',
      flightTrainName: editFormData.flightTrainName || '',
      returnFlightName: editFormData.returnFlightName || '',
      flightTrainNumber: editFormData.flightTrainNumber || '',
      returnFlightNumber: editFormData.returnFlightNumber || '',
      arrivalTimeGoa: editFormData.arrivalTimeGoa || '',
      departure_time_from_source: editFormData.departure_time_from_source || '',
      returnFlightDepartureTime: editFormData.returnFlightDepartureTime || '',
    }

    try {
      axios.post(`${process.env.REACT_APP_API_END_POINT}/set-flight-details`, { visitorId: editVisitorData._id, data: requestData})
        .then((resData) => {
          console.log('Response data: ', editVisitorData._id, resData);
        }).catch((err) => {
          console.error('An error occurred: ', err);
        });

      setEditFormData({
        travelMode: '',
        travelReturnMode: '',
        departureCity: '',
        flightTrainName: '',
        returnFlightName: '',
        flightTrainNumber: '',
        returnFlightNumber: '',
        arrivalTimeGoa: '',
        departure_time_from_source: '',
        returnFlightDepartureTime: '',
      });

      setEditDepartureDate(null);
      setEditReturnFlightDate(null);

      setTimeout(() => {
        setShowEditBookingModal(false);
        window.location.reload(true);
      }, 1000)
    } catch (error) {
      console.error('Error occurred while deleting buyer: ', error);
    }
  }

  useEffect(() => {
    setFormData({
      ...formData,
      travelMode: bookingData?.travelMode,
      travelReturnMode: bookingData?.travelReturnMode,
      departureCity: bookingData?.departureCity,
      returnCity: bookingData?.returnCity,
      flightTrainName: bookingData?.flightTrainName,
      returnFlightName: bookingData?.returnFlightName,
      flightTrainNumber: bookingData?.flightTrainNumber,
      returnFlightNumber: bookingData?.returnFlightNumber,
      arrivalTimeGoa: bookingData?.arrivalTimeGoa,
      departure_time_from_source: bookingData?.departure_time_from_source,
      returnFlightDepartureTime: bookingData?.returnFlightDepartureTime,
    });

    setDepartureDate(bookingData?.departureDate);
    setReturnFlightDate(bookingData?.returnFlightDate);
  }, [bookingData]);

  const closeBookingModal = () => {
    setShowBookingModal(false);
    // setFormData({
    //   ...formData,
    //   travelMode: '',
    //   travelReturnMode: '',
    //   departureCity: '',
    //   flightTrainName: '',
    //   returnFlightName: '',
    //   flightTrainNumber: '',
    //   returnFlightNumber: '',
    //   arrivalTimeGoa: '',
    //   departure_time_from_source: '',
    //   returnFlightDepartureTime: '',
    // });

    // setDepartureDate(null);
    // setReturnFlightDate(null);
  }

  const [selectedMembers, setSelectedMembers] = useState([]);
  const handleCheckboxChange2 = (isChecked, member) => {
    if (isChecked) {
      setSelectedMembers((prev) => [...prev, member]);
    } else {
      setSelectedMembers((prev) =>
        prev.filter((selectedMember) => selectedMember.barcode !== member.barcode)
      );
    }
  };

  const dateValidation = `Select Between DateRange ${moment(getNextDayFormatted(process.env.REACT_APP_START_DATE)).format('lll')} - ${moment(process.env.REACT_APP_END_DATE).format('lll')}`

  return (
    <>
      {isHovered && (
        <div className="">
          <Modal
            size="xl"
            show={isModalVisible}
            onHide={() => setIsModalVisible(false)}
            animation={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-auto">
              {" "}
              <img alt="" src={imageValue} className="modal-img" />
            </Modal.Body>
          </Modal>
        </div>
      )}

      <Modal
        size="xl"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Buyer</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          <div>Are you sure you want to delete the buyer?</div>
          <Button onClick={handleDeleteBuyer}>Yes</Button>
          <Button onClick={() => setShowDeleteModal(false)}>No</Button>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={showBookingModal}
        onHide={closeBookingModal}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Flight Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          {loadingState ? (
            <>
              {memberTicketBookedList.length > 0 && (
                <>
                  <Table
                    responsive
                    className="table table-striped my-3 p-3 data-table"
                  >
                    <thead>
                      <tr>
                        <th scope="col">Select</th>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Reg. No.</th>
                        <th scope="col">Buyer Name</th>
                        <th scope="col">Mobile No.</th>
                      </tr>
                    </thead>

                    <tbody>
                      {memberTicketBookedList.map((data, idx) => {
                        return (
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange2(e.target.checked, data)}
                              />
                            </td>
                            <th scope="row">{idx + 1}</th>
                            <td>{data.barcode}</td>
                            <td>{data.visitor_name}</td>
                            <td>{data.mobile_number}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <form onSubmit={saveFlightDetails}>
                    <div className="text-center ">
                      <h4 className="form-heading">{"Arrival Detail"}</h4>
                    </div>
                    <div className="text-center ">
                      <span >{"Arrival City Delhi"}</span>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Travel Mode <font color="#FF0000">*</font>
                        </label>
                        <Select
                          className="my-2"
                          options={travelModerarrivalArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            formData.travelMode
                              ? { value: formData.travelMode, label: formData.travelMode }
                              : null
                          }
                          required
                          onChange={(selectedOption) => { setFormData({ ...formData, travelMode: selectedOption ? selectedOption.value : null });}}

                          isSearchable
                          placeholder="Choose One"
                        />

                      </div>

                      {formData.travelMode === "Self" ? null :
                        <>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Departure City (Arriving From Which City) <font color="#FF0000">*</font>
                            </label>
                            <input
                              className="form-control my-2"
                              value={formData.departureCity}
                              required
                              placeholder="Arrival City"
                              name="departureCity"
                              onChange={handleChange}
                            />
                          </div>
                        </>}

                      {formData.travelMode === "Self" ? 
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Date of Arrival
                          </label>
                          <DatePickers
                            selectedDate={departureDate} setSelectedDate={setDepartureDate} lable={'Date of Departure'} required={false}
                          />
                          {error_1st && <span style={{ color: 'red' }}>{dateValidation}</span>}
                        </div> :
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Date of Arrival <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            selectedDate={departureDate} setSelectedDate={setDepartureDate} lable={'Date of Departure'} required={true}
                          />
                          {error_1st && <span style={{ color: 'red' }}>{dateValidation}</span>}
                        </div>}

                      {formData.travelMode === "Self" ? null : <>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train name <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            value={formData.flightTrainName}
                            required
                            placeholder="Flight/Train name"
                            name="flightTrainName"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Number <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            placeholder="Flight/Train Number"
                            value={formData.flightTrainNumber}
                            required
                            name="flightTrainNumber"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Arrival time at Delhi <font color="#FF0000">*</font>
                          </label>
                          <input
                            // type="text"
                            type="time"
                            className="form-control my-2"
                            placeholder="Arrival Time"
                            value={formData.arrivalTimeGoa}
                            name="arrivalTimeGoa"
                            required
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Departure Time From Source <font color="#FF0000">*</font>
                          </label>
                          <input
                            // type="text"
                            type="time"
                            className="form-control my-2"
                            placeholder="Arrival Time"
                            value={formData.departure_time_from_source}
                            name="departure_time_from_source"
                            required
                            onChange={handleChange}
                          />
                        </div>

                      </>}
                      <hr />
                    </div>

                    <div className="text-center">
                      <h4 className="form-heading">{"Returning Detail"}</h4>
                    </div>
                    <div className="text-center">
                      <span>{"Departure City Delhi"}</span>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Travel Mode<font color="#FF0000">*</font>
                        </label>
                        <Select
                          className="my-2"
                          options={travelModerDepartureArray.map((data) => ({
                            value: data,
                            label: data,
                          }))}
                          value={
                            formData.travelReturnMode
                              ? { value: formData.travelReturnMode, label: formData.travelReturnMode }
                              : null
                          }
                          onChange={(selectedOption) => { setFormData({ ...formData, travelReturnMode: selectedOption ? selectedOption.value : null }); setShowBlock6th(true) }}

                          required
                          isSearchable
                          placeholder="Choose One"
                        />
                      </div>

                      {formData.travelReturnMode === "Self" ? null :
                        <>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Arrival City (Arriving In Which City) <font color="#FF0000">*</font>
                            </label>

                            <input
                              className="form-control my-2"
                              value={formData.returnCity}
                              required

                              placeholder="Returning City"
                              name="returnCity"
                              onChange={handleChange}
                            />
                          </div>
                        </>}

                      {formData.travelReturnMode === "Self" ?
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Date
                          </label>
                          <DatePickers
                            required={false}
                            selectedDate={returnFlightDate} setSelectedDate={setReturnFlightDate} lable={'Date of Departure'}
                          />
                          {error_3rd && <span style={{ color: 'red' }}>{dateValidation}</span>}
                        </div> :
                        <div className="form-group col-lg-4 col-md-6">
                        <label className="fw-bold">
                          Flight/Train Date <font color="#FF0000">*</font>
                        </label>
                        <DatePickers
                          required={true}
                          selectedDate={returnFlightDate} setSelectedDate={setReturnFlightDate} lable={'Date of Departure'}
                        />
                        {error_3rd && <span style={{ color: 'red' }}>{dateValidation}</span>}
                      </div> }

                      {formData.travelReturnMode === "Self" ? null : <>
                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Name <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            value={formData.returnFlightName}
                            required

                            placeholder="Flight/Train Name"
                            name="returnFlightName"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Number <font color="#FF0000">*</font>
                          </label>
                          <input
                            className="form-control my-2"
                            value={formData.returnFlightNumber}
                            required

                            placeholder="Flight/Train Number"
                            name="returnFlightNumber"
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Time <font color="#FF0000">*</font>
                          </label>
                          <input
                            type="time"
                            required
                            className="form-control my-2"
                            placeholder="Returning Time"
                            value={formData.returnFlightDepartureTime}
                            name="returnFlightDepartureTime"
                            onChange={handleChange}
                          />
                        </div>
                      </>}

                    </div>
                    <hr />

                    <Button type='submit'>
                      Submit
                    </Button>
                  </form>
                </>
              )}
              {memberTicketBookedList.length <= 0 && (
                <>
                  <div className="no-data-div">
                    <h5 className="no-data-heading">All Bookings Completed</h5>
                  </div>
                </>
              )}
            </>
            ) : (
              <div className="content-height d-flex justify-content-center align-items-center">
                <Spinner animation="border" size="lg" />
              </div>
            )
          }
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={showEditBookingModal}
        onHide={() => setShowEditBookingModal(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Flight Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          {editFormData.travelMode ?
          <form onSubmit={editFlightDetails}>
            <div className="text-center ">
              <h4 className="form-heading">{"Arrival Detail"}</h4>
            </div>
            <div className="text-center ">
              <span >{"Arrival City Delhi"}</span>
            </div>
            <hr />

            <div className="row">
              <div className="form-group col-lg-4 col-md-6">
                <label className="fw-bold">
                  Travel Mode <font color="#FF0000">*</font>
                </label>
                <Select
                  className="my-2"
                  options={travelModerarrivalArray.map((data) => ({
                    value: data,
                    label: data,
                  }))}
                  value={
                    editFormData.travelMode
                      ? { value: editFormData.travelMode, label: editFormData.travelMode }
                      : null
                  }
                  required
                  onChange={(selectedOption) => { setEditFormData({ ...editFormData, travelMode: selectedOption ? selectedOption.value : null });}}

                  isSearchable
                  placeholder="Choose One"
                />

              </div>

              {editFormData.travelMode === "Self" ? null :
                <>
                  <div className="form-group col-lg-4 col-md-6">
                    <label className="fw-bold">
                      Departure City (Arriving From Which City) <font color="#FF0000">*</font>
                    </label>
                    <input
                      className="form-control my-2"
                      value={editFormData.departureCity}
                      required
                      placeholder="Arrival City"
                      name="departureCity"
                      onChange={(e) => handleChange(e, true)}
                    />
                  </div>
                </>}

              {editFormData.travelMode === "Self" ?
                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Date of Arrival
                  </label>
                  <DatePickers
                    selectedDate={editDepartureDate} setSelectedDate={setEditDepartureDate} lable={'Date of Departure'} required={false}
                  />
                  {error_12th && <span style={{ color: 'red' }}>{dateValidation}</span>}
                </div> :
                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Date of Arrival <font color="#FF0000">*</font>
                  </label>
                  <DatePickers
                    selectedDate={editDepartureDate} setSelectedDate={setEditDepartureDate} lable={'Date of Departure'} required={true}
                  />
                  {error_12th && <span style={{ color: 'red' }}>{dateValidation}</span>}
                </div>}

              {editFormData.travelMode === "Self" ? null : <>
                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Flight/Train name <font color="#FF0000">*</font>
                  </label>
                  <input
                    className="form-control my-2"
                    value={editFormData.flightTrainName}
                    required
                    placeholder="Flight/Train name"
                    name="flightTrainName"
                    onChange={(e) => handleChange(e, true)}
                  />
                </div>
                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Flight/Train Number <font color="#FF0000">*</font>
                  </label>
                  <input
                    className="form-control my-2"
                    placeholder="Flight/Train Number"
                    value={editFormData.flightTrainNumber}
                    required
                    name="flightTrainNumber"
                    onChange={(e) => handleChange(e, true)}
                  />
                </div>
                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Arrival time at Delhi <font color="#FF0000">*</font>
                  </label>
                  <input
                    // type="text"
                    type="time"
                    className="form-control my-2"
                    placeholder="Arrival Time"
                    value={editFormData.arrivalTimeGoa}
                    name="arrivalTimeGoa"
                    required
                    onChange={(e) => handleChange(e, true)}
                  />
                </div>
                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Departure Time From Source <font color="#FF0000">*</font>
                  </label>
                  <input
                    // type="text"
                    type="time"
                    className="form-control my-2"
                    placeholder="Arrival Time"
                    value={editFormData.departure_time_from_source}
                    name="departure_time_from_source"
                    required
                    onChange={(e) => handleChange(e, true)}
                  />
                </div>

              </>}
              <hr />
            </div>

            <div className="text-center">
              <h4 className="form-heading">{"Returning Detail"}</h4>
            </div>
            <div className="text-center">
              <span>{"Departure City Delhi"}</span>
            </div>
            <hr />

            <div className="row">
              <div className="form-group col-lg-4 col-md-6">
                <label className="fw-bold">
                  Travel Mode<font color="#FF0000">*</font>
                </label>
                <Select
                  className="my-2"
                  options={travelModerDepartureArray.map((data) => ({
                    value: data,
                    label: data,
                  }))}
                  value={
                    editFormData.travelReturnMode
                      ? { value: editFormData.travelReturnMode, label: editFormData.travelReturnMode }
                      : null
                  }
                  onChange={(selectedOption) => { setEditFormData({ ...editFormData, travelReturnMode: selectedOption ? selectedOption.value : null }); }}

                  required
                  isSearchable
                  placeholder="Choose One"
                />
              </div>

              {editFormData.travelReturnMode === "Self" ? null :
                <>
                  <div className="form-group col-lg-4 col-md-6">
                    <label className="fw-bold">
                      Arrival City (Arriving In Which City) <font color="#FF0000">*</font>
                    </label>

                    <input
                      className="form-control my-2"
                      value={editFormData.returnCity}
                      required

                      placeholder="Returning City"
                      name="returnCity"
                      onChange={(e) => handleChange(e, true)}
                    />
                  </div>
                </>}

              {editFormData.travelReturnMode === "Self" ?
                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Flight/Train Date
                  </label>
                  <DatePickers
                    required={false}
                    selectedDate={editReturnFlightDate} setSelectedDate={setEditReturnFlightDate} lable={'Date of Departure'}
                  />
                  {error_13th && <span style={{ color: 'red' }}>{dateValidation}</span>}
                </div> :
                <div className="form-group col-lg-4 col-md-6">
                <label className="fw-bold">
                  Flight/Train Date <font color="#FF0000">*</font>
                </label>
                <DatePickers
                  required={true}
                  selectedDate={editReturnFlightDate} setSelectedDate={setEditReturnFlightDate} lable={'Date of Departure'}
                />
                {error_13th && <span style={{ color: 'red' }}>{dateValidation}</span>}
              </div> }

              {editFormData.travelReturnMode === "Self" ? null : <>
                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Flight/Train Name <font color="#FF0000">*</font>
                  </label>
                  <input
                    className="form-control my-2"
                    value={editFormData.returnFlightName}
                    required

                    placeholder="Flight/Train Name"
                    name="returnFlightName"
                    onChange={(e) => handleChange(e, true)}
                  />
                </div>

                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Flight/Train Number <font color="#FF0000">*</font>
                  </label>
                  <input
                    className="form-control my-2"
                    value={editFormData.returnFlightNumber}
                    required

                    placeholder="Flight/Train Number"
                    name="returnFlightNumber"
                    onChange={(e) => handleChange(e, true)}
                  />
                </div>

                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Flight/Train Time <font color="#FF0000">*</font>
                  </label>
                  <input
                    type="time"
                    required
                    className="form-control my-2"
                    placeholder="Returning Time"
                    value={editFormData.returnFlightDepartureTime}
                    name="returnFlightDepartureTime"
                    onChange={(e) => handleChange(e, true)}
                  />
                </div>
              </>}

            </div>
            <hr />

            <Button type='submit'>
              Edit
            </Button>
          </form> : <>
                No Flight Details Added
          </>}
        </Modal.Body>
      </Modal>

      {showPopup && (
        <div className="">
          <Modal
            size="xl"
            show={showPopup}
            onHide={() => setShowPopup(false)}
            animation={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Buyer</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-auto">
              <form onSubmit={handleSubmit} className="row">
                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Title <font color="#FF0000">*</font>
                  </label>
                  <Select
                    className="my-2"
                    options={titleData.map((data) => ({
                      value: data,
                      label: data,
                    }))}
                    required={formData.title !== "others" ? true : false}
                    value={
                      formData.title && formData.title !== "others"
                        ? { value: formData.title, label: formData.title }
                        : null
                    }
                    onChange={(selectedOption) => {
                      setFormData({ ...formData, title: selectedOption.value });
                    }}
                    isSearchable
                    placeholder="Select title"
                  />
                </div>

                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Name of the Person <font color="#FF0000">*</font>
                  </label>
                  <input
                    required
                    className="form-control my-2"
                    placeholder="Person Name"
                    value={formData.personName}

                    name="personName"
                    onChange={handleChange}
                  />

                </div>

                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Mobile number <font color="#FF0000">*</font>
                  </label>
                  <input
                    className="form-control my-2"
                    required
                    placeholder="Mobile number"
                    value={formData.mobileNumber}
                    name="mobileNumber"
                    onChange={handleChange}
                  />
                  {fristMobileNumberError &&
                    <div style={{ color: fristMobileNumberError.already_exists ? "red" : "green", fontSize: '12px', marginBottom: '10px' }}>
                      {fristMobileNumberError.already_exists ? "The number is already taken and not available." : fristMobileNumberError.message}
                    </div>
                  }
                </div>

                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="emailAddress"
                    className="form-control my-2"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email Address"
                  />
                </div>

                <div className="form-group col-lg-4 col-md-6">
                  <label className="fw-bold">
                    Designation <font color="#FF0000">*</font>
                  </label>

                  {formData.designation === "others" ? (
                    <input
                      type="text"
                      required
                      className="form-control my-2"
                      placeholder="Enter your designation"
                      value={formData.otherDesignation}
                      onChange={(e) => { handleOtherDesignationChange(e, '1st'); }}
                    />
                  ) :

                    (
                      <Select
                        className="my-2"
                        options={designationArray.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        required={formData.designation !== "others" ? true : false}
                        value={
                          formData.designation && formData.designation !== "others"
                            ? { value: formData.designation, label: formData.designation }
                            : null
                        }
                        onChange={(e) => { handleSelectChange(e, '1st'); setShowBlock3rd(true); }}
                        isSearchable
                        placeholder="Select designation"
                      />
                    )}
                </div>

                <div className="col-lg-4 col-12 regis-form-group">
                  <label className="fw-bold">
                    Photo <font color="#FF0000">*</font>
                  </label>
                  <CommonFileUploader handlePhoto={(e) => setPhoto(e.target.files[0])} fileName={photo ? photo?.name : "Select File"}
                    duplicateError={duplicateError}
                    accept=".jpg, .jpeg, .png"
                  />


                </div>

                <div className="col-lg-4 col-12 regis-form-group">
                  <label className="fw-bold">
                    Upload ID Front(Aadhar Card) <font color="#FF0000">*</font>
                  </label>
                  <CommonFileUploader handlePhoto={(e) => setIDProofFront(e.target.files[0])} fileName={idProofFront ? idProofFront?.name : "Select File"}
                    duplicateError={duplicateError}
                    accept=".jpg, .jpeg, .png"
                  />


                </div>

                <div className="col-lg-4 col-12 regis-form-group">
                  <label className="fw-bold">
                    Upload ID Back(Aadhar Card) <font color="#FF0000">*</font>
                  </label>
                  <CommonFileUploader handlePhoto={(e) => setIDProofBack(e.target.files[0])} fileName={idProofBack ? idProofBack?.name : "Select File"}
                    duplicateError={duplicateError}
                    accept=".jpg, .jpeg, .png"
                  />


                </div>

                <div className='my-3 ms-2' >
                  {/* <label>
                    <input
                      type="checkbox"
                      checked={isChecked1st}
                      onChange={() => handleCheckboxChange(isChecked1st, setIsChecked1st)}
                    />
                    <span className='ms-3'>
                      Click Here to add Second Person
                    </span>
                  </label> */}
                </div>

                <Button type='submit' className="col-lg-4 col-12 m-auto">
                  Submit
                </Button>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      )}

      <Modal
        size="xl"
        show={showEditProfile}
        onHide={() => setShowEditProfile(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Buyer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto">
          <form onSubmit={handleEditSubmit} className="row">
            <div className="form-group col-lg-4 col-md-6">
              <label className="fw-bold">
                Title <font color="#FF0000">*</font>
              </label>
              <Select
                className="my-2"
                options={titleData.map((data) => ({
                  value: data,
                  label: data,
                }))}
                required={formData.title !== "others" ? true : false}
                value={
                  formData.title && formData.title !== "others"
                    ? { value: formData.title, label: formData.title }
                    : null
                }
                onChange={(selectedOption) => {
                  setFormData({ ...formData, title: selectedOption.value });
                }}
                isSearchable
                placeholder="Select title"
              />
            </div>

            <div className="form-group col-lg-4 col-md-6">
              <label className="fw-bold">
                Name of the Person <font color="#FF0000">*</font>
              </label>
              <input
                required
                className="form-control my-2"
                placeholder="Person Name"
                value={formData.personName}

                name="personName"
                onChange={handleChange}
              />
            </div>

            <div className="form-group col-lg-4 col-md-6">
              <label className="fw-bold">
                Mobile number <font color="#FF0000">*</font>
              </label>
              <input
                className="form-control my-2"
                required
                placeholder="Mobile number"
                value={formData.mobileNumber}
                name="mobileNumber"
                onChange={handleChange}
                disabled
              />
            </div>

            <div className="form-group col-lg-4 col-md-6">
              <label className="fw-bold">
                Email Address
              </label>
              <input
                type="email"
                name="emailAddress"
                className="form-control my-2"
                value={formData.emailAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Email Address"
              />
            </div>

            <div className="form-group col-lg-4 col-md-6">
              <label className="fw-bold">
                Designation <font color="#FF0000">*</font>
              </label>

              {formData.designation === "others" ?
                (
                  <input
                    type="text"
                    required
                    className="form-control my-2"
                    placeholder="Enter your designation"
                    value={formData.otherDesignation}
                    onChange={(e) => { handleOtherDesignationChange(e, '1st'); }}
                  />
                ) :
                (
                  <Select
                    className="my-2"
                    options={designationArray.map((data) => ({
                      value: data,
                      label: data,
                    }))}
                    required={formData.designation !== "others" ? true : false}
                    value={
                      formData.designation && formData.designation !== "others"
                        ? { value: formData.designation, label: formData.designation }
                        : null
                    }
                    onChange={(e) => { handleSelectChange(e, '1st'); setShowBlock3rd(true); }}
                    isSearchable
                    placeholder="Select designation"
                  />
                )
              }
            </div>

            <div className="col-lg-4 col-12 regis-form-group">
              <label className="fw-bold">
                Photo <font color="#FF0000">*</font>
              </label>
              <CommonFileUploader handlePhoto={(e) => setPhoto(e.target.files[0])} fileName={photo ? photo?.name : "Select File"}
                duplicateError={duplicateError}
                accept=".jpg, .jpeg, .png"
              />
            </div>

            <div className="col-lg-4 col-12 regis-form-group">
              <label className="fw-bold">
                Upload ID Front(Aadhar Card) <font color="#FF0000">*</font>
              </label>
              <CommonFileUploader handlePhoto={(e) => setIDProofFront(e.target.files[0])} fileName={idProofFront ? idProofFront?.name : "Select File"}
                duplicateError={duplicateError}
                accept=".jpg, .jpeg, .png"
              />
            </div>

            <div className="col-lg-4 col-12 regis-form-group">
              <label className="fw-bold">
                Upload ID Back(Aadhar Card) <font color="#FF0000">*</font>
              </label>
              <CommonFileUploader handlePhoto={(e) => setIDProofBack(e.target.files[0])} fileName={idProofBack ? idProofBack?.name : "Select File"}
                duplicateError={duplicateError}
                accept=".jpg, .jpeg, .png"
              />
            </div>

            <Button type='submit' className="col-lg-4 col-12 m-auto">
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <div>
        <div className="text-center">
          <h4 className="form-heading">{extractedPart === `gcc-retailer-registration-form` ? "GCC Retailers Detail Form" : "Buyer Detail Form"}</h4>

          <button className="btn btn-primary" style={{position: 'absolute',
    right: '15%',
    top: '20px'}} onClick={(e) => {
            e.preventDefault();
            localStorage.removeItem('password_form');
            localStorage.removeItem('barcode');
            localStorage.removeItem('from_status');
            localStorage.removeItem('mobile_number');
            window.location.reload(true);
          }}>
            Logout
          </button>
        </div>

        <section>

          <div className="container">
            <div className="form-body-section">

              <div className="form-body">
                <div>

                </div>
                <form>


                  <div className="row">
                    {/* <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Representative <font color="#FF0000">*</font>

                      </label>
                      <Select
                        className="my-2"
                        options={representative_list.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        required
                        value={
                          formData.representative
                            ? { value: formData.representative, label: formData.representative }
                            : null
                        }
                        onChange={(selectedOption) => setFormData({ ...formData, representative: selectedOption ? selectedOption.value : null })}


                        isSearchable
                        placeholder="Select Representative"
                      />
                    </div> */}
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Gst Number <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="GST Number"
                        required
                        value={gst_number}
                        disabled={gst_data_present?.company}
                        name="companyName"
                        onChange={handleGstNumberChange}
                      />
                      {showError && <p className="text-danger">{showError}</p>}
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Name of the Company <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Company Name"
                        required
                        disabled={gst_data_present?.company}
                        value={formData.companyName}

                        name="companyName"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Company Address <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Address"
                        required
                        disabled={gst_data_present?.address}
                        value={company_address}

                        name="companyName"
                        onChange={(e) => { setCompany_address(e.target.value) }}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        City <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="city"
                        required
                        disabled={gst_data_present?.city}
                        value={company_city}

                        name="companyName"
                        onChange={(e) => { setCompany_city(e.target.value) }}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        State <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="State"
                        required
                        disabled={gst_data_present?.state}
                        value={company_state}

                        name="companyName"
                        onChange={(e) => { setCompany_state(e.target.value) }}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Company Pincode <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Company Pincode"
                        required
                        disabled={gst_data_present?.pincode}
                        value={company_pincode}

                        name="companyName"
                        onChange={(e) => { setCompany_pincode(e.target.value) }}
                      />
                    </div>
                    {/* <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Number of Stores <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Number of stores"
                        required
                        value={no_of_stores}
                        // maxLength={2}

                        name="companyName"
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[^0-9]/g, '')
                          if (/^\d*$/.test(value)) { // Allow only digits
                            set_no_of_stores(value);
                          }
                        }}

                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Total Square feet area <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="Area in sqft"
                        required
                        value={sqft_area}
                        maxLength={6}

                        name="companyName"
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[^0-9]/g, '')
                          if (/^\d*$/.test(value)) { // Allow only digits
                            set_sqft_area(value);
                          }
                        }}
                      />
                    </div>
                    <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        No. of staff <font color="#FF0000">*</font>
                      </label>
                      <input
                        className="form-control my-2"
                        placeholder="No of staff"
                        required
                        value={no_of_staff}
                        // maxLength={2}
                        name="companyName"
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[^0-9]/g, '')
                          if (/^\d*$/.test(value)) { // Allow only digits
                            set_no_of_staff(value);
                            setShowBlock5th(true)
                          }
                        }}
                      />
                    </div> */}

                    {/* <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Company Category <font color="#FF0000">*</font>
                      </label>
                      <Select
                        className="my-2"
                        options={company_category_array.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        value={
                          company_category
                            ? { value: company_category, label: company_category }
                            : null
                        }
                        required
                        onChange={(selectedOption) => { set_company_category(selectedOption.value); setShowBlock5th(true) }}

                        isSearchable
                        placeholder="Choose One"
                      />

                    </div> */}
                  </div>
                  {showBlock5th && <div className="row">


                    {/* <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Category Percentage <font color="#FF0000">*</font>

                      </label>
                      <div>

                        <span>
                          Out of your 100% annual sales, kindly provide the ratio between Gold, Diamond & Others, this will help us in scheduling your meeting with the PMI participants.
                        </span>
                      </div>

                      <div className="table-responsive">
                        <Table className="table table-striped my-3 p-3 data-table">
                          <thead>
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">Category</th>
                              <th scope="col">Percentage</th>
                            </tr>
                          </thead>
                          <tbody>
                            {categories.map((category, index) => (
                              <tr key={category.id}>
                                <th scope="row">{index + 1}</th>
                                <td>{category.name}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={category.percentage}
                                    maxLength={2}
                                    onChange={(e) =>
                                      handlePercentageChange(category.id, e.target.value)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      {error && <span style={{ color: 'red' }}>


                        {error} -: still {calculateRemainingPercentage(categories)} remaining percentage
                      </span>
                      }
                    </div>
                    <div className="form-group col-lg-6 col-md-6">
                      <label className="fw-bold">
                        Category Priority <font color="#FF0000">*</font>
                      </label>
                      <div>

                        <span>
                          Please specify your priority (1 being first and 5 being last priority) for creating your meeting schedule at PMI
                        </span>
                      </div>
                      <div className="table-responsive">
                        <Table className="table table-striped my-3 p-3 data-table">
                          <thead>
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">Category</th>
                              <th scope="col">Priority</th>
                            </tr>
                          </thead>
                          <tbody>
                            {other_category.map((category, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{category.key}</td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    maxLength={1}
                                    min="0"
                                    value={category.value}
                                    onChange={(e) => {
                                      handlePriorityChange(index, e.target.value);


                                      setShowBlock2nd(true)
                                    }
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {other_error && <span style={{ color: 'red' }}>{other_error}</span>}


                      </div>
                    </div> */}
                    {/* <div className="form-group col-lg-4 col-md-6">
                      <label className="fw-bold">
                        Room Occupancy
                      </label>
                      <Select
                        className="my-2"
                        required
                        options={occupancy_list.map((data) => ({
                          value: data,
                          label: data,
                        }))}
                        value={
                          formData.roomOccupancy
                            ? { value: formData.roomOccupancy, label: formData.roomOccupancy }
                            : null
                        }
                        onChange={(selectedOption) => { setFormData({ ...formData, roomOccupancy: selectedOption ? selectedOption.value : null }); setShowBlock2nd(true); }}


                        isSearchable
                        placeholder="Select designation"
                      />

                    </div> */}


                  </div>}

                  {
                    showBlock4th &&
                    <>
                      <div className="text-center">
                        <h4 className="form-heading">{"Returning Detail"}</h4>
                      </div>
                      <div className="text-center">
                        <span>{"Departure City Delhi"}</span>
                      </div>
                      <hr />

                      <div className="row">

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Travel Mode<font color="#FF0000">*</font>
                          </label>
                          <Select
                            className="my-2"
                            options={travelModerDepartureArray.map((data) => ({
                              value: data,
                              label: data,
                            }))}
                            value={
                              formData.travelReturnMode
                                ? { value: formData.travelReturnMode, label: formData.travelReturnMode }
                                : null
                            }
                            onChange={(selectedOption) => { setFormData({ ...formData, travelReturnMode: selectedOption ? selectedOption.value : null }); setShowBlock6th(true) }}

                            required
                            isSearchable
                            placeholder="Choose One"
                          />

                        </div>

                        {formData.travelReturnMode === "Self" ? null :
                          <>
                            {/* <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Returning PNR <font color="#FF0000">*</font>
                              </label>
                              <input
                                required
                                className="form-control my-2"
                                placeholder="Returning PNR"
                                value={formData.returnPNR}

                                name="returnPNR"
                                onChange={handleChange}
                              />
                            </div> */}

                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Arrival City (Arriving In Which City) <font color="#FF0000">*</font>
                              </label>

                              <input
                                className="form-control my-2"
                                value={formData.returnCity}
                                required

                                placeholder="Returning City"
                                name="returnCity"
                                onChange={handleChange}
                              />
                            </div>
                          </>}

                        <div className="form-group col-lg-4 col-md-6">
                          <label className="fw-bold">
                            Flight/Train Date <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            required={true}
                            selectedDate={returnFlightDate} setSelectedDate={setReturnFlightDate} lable={'Date of Departure'}

                          />
                          {error_3rd && <span style={{ color: 'red' }}>{dateValidation}</span>}

                        </div>
                        {formData.travelReturnMode === "Self" ? null : <>




                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Flight/Train Name <font color="#FF0000">*</font>
                            </label>
                            <input
                              className="form-control my-2"
                              value={formData.returnFlightName}
                              required

                              placeholder="Flight/Train Name"
                              name="returnFlightName"
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Flight/Train Number <font color="#FF0000">*</font>
                            </label>
                            <input
                              className="form-control my-2"
                              value={formData.returnFlightNumber}
                              required

                              placeholder="Flight/Train Number"
                              name="returnFlightNumber"
                              onChange={handleChange}
                            />
                          </div>

                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Flight/Train Time <font color="#FF0000">*</font>
                            </label>
                            <input
                              // type="text"
                              type="time"
                              required

                              className="form-control my-2"
                              placeholder="Returning Time"
                              value={formData.returnFlightDepartureTime}
                              name="returnFlightDepartureTime"
                              onChange={handleChange}
                            />
                          </div>




                        </>}

                      </div>
                      {
                        isChecked1st && <div >
                          <label>
                            <input
                              type="checkbox"
                              checked={isChecked3rd}
                              onChange={() => handleCheckboxChange(isChecked3rd, setIsChecked3rd)}
                            />
                            <span className='ms-3'>

                              Add same details for {formData.personName2nd}
                            </span>
                          </label>
                          {/* <p>{isChecked1st ? "Checkbox is checked" : "Checkbox is unchecked"}</p> */}
                        </div>
                      }
                      <hr></hr>

                      {!isChecked3rd &&
                        <div className="row">

                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Travel Mode (2nd Person)<font color="#FF0000">*</font>
                            </label>
                            <Select
                              className="my-2"
                              required

                              options={travelModerDepartureArray2nd.map((data) => ({
                                value: data,
                                label: data,
                              }))}
                              value={
                                formData.travelReturnMode2nd
                                  ? { value: formData.travelReturnMode2nd, label: formData.travelReturnMode2nd }
                                  : null
                              }
                              onChange={(selectedOption) => setFormData({ ...formData, travelReturnMode2nd: selectedOption ? selectedOption.value : null })}


                              isSearchable
                              placeholder="Choose One"
                            />

                          </div>


                          {formData.travelReturnMode2nd === "Self" ? null :
                            <>
                              {/* <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Returning PNR (2nd Person)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  className="form-control my-2"
                                  required

                                  placeholder="Returning PNR"
                                  value={formData.returnPNR2nd}

                                  name="returnPNR2nd"
                                  onChange={handleChange}
                                />
                              </div> */}
                              <div className="form-group col-lg-4 col-md-6">
                                <label className="fw-bold">
                                  Arrival City 2nd Person (Arriving In Which City)<font color="#FF0000">*</font>
                                </label>
                                <input
                                  className="form-control my-2"
                                  value={formData.returnCity2nd}
                                  required

                                  placeholder="Returning City"
                                  name="returnCity2nd"
                                  onChange={handleChange}
                                />
                              </div>
                            </>}
                          <div className="form-group col-lg-4 col-md-6">
                            <label className="fw-bold">
                              Flight/Train Date (2nd Person)<font color="#FF0000">*</font>
                            </label>
                            <DatePickers
                              selectedDate={returnFlightDate2nd} setSelectedDate={setReturnFlightDate2nd} lable={'Date of Departure'}

                            />
                            {error_4th && <span style={{ color: 'red' }}>{dateValidation}</span>}

                          </div>
                          {formData.travelReturnMode2nd === "Self" ? null : <>



                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Name (2nd Person)<font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.returnFlightName2nd}
                                required

                                placeholder="Flight/Train Name"
                                name="returnFlightName2nd"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Number (2nd Person)<font color="#FF0000">*</font>
                              </label>
                              <input
                                className="form-control my-2"
                                value={formData.returnFlightNumber2nd}
                                required

                                placeholder="Flight/Train Number"
                                name="returnFlightNumber2nd"
                                onChange={handleChange}
                              />
                            </div>

                            <div className="form-group col-lg-4 col-md-6">
                              <label className="fw-bold">
                                Flight/Train Time (2nd Person)<font color="#FF0000">*</font>
                              </label>
                              <input
                                // type="text"
                                type="time"
                                required

                                className="form-control my-2"
                                placeholder="Returning Time"
                                value={formData.returnFlightDepartureTime2nd}
                                name="returnFlightDepartureTime2nd"
                                onChange={handleChange}
                              />
                            </div>



                          </>}

                        </div>


                      }
                    </>
                  }

                  {/* {showBlock6th &&
                    <>

                      <div className="text-center">
                        <h4 className="form-heading">{"Hotel Detail"}</h4>

                      </div>
                      <div className="text-center my-1 ">

                        <h5 className='font-weight-bold'>Hyatt Regency Gurugram, NH48, Sector 83, New Delhi, Gurugram, Haryana 122004</h5>
                      </div>
                      <hr />
                      <div className="row my-3">
                        <div className="form-group col-lg-6 col-md-6">
                          <label className="fw-bold">
                            Hotel Check-In Date <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            selectedDate={hotelCheckInDate} setSelectedDate={setHotelCheckInDate} lable={'Hotel Check-In Date (2nd Person)'} required={true}

                          />
                          {error_5th && <span style={{ color: 'red' }}>{dateValidation}</span>}

                        </div>
                        <div className="form-group col-lg-6 col-md-6" >
                          <label className="fw-bold">
                            Hotel Check-Out Date <font color="#FF0000">*</font>
                          </label>
                          <DatePickers
                            selectedDate={hotelCheckOutDate} setSelectedDate={setHotelCheckOutDate} lable={'Hotel Check-Out Date (2nd Person)'}
                            required={true}

                          />
                          {error_6th && <span style={{ color: 'red' }}>{dateValidation}</span>}

                        </div>

                      </div>
                    </>
                  } */}

                  {/* <Tandcform /> */}

                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      id="acceptGuidelines"
                      checked={isChecked}
                      onChange={(event) => setIsChecked(event.target.checked)}
                    />
                    <label htmlFor="acceptGuidelines" className='ms-1'>
                      I agree to the above mentioned Guidelines for PMI
                    </label>
                  </div>
                  <div className="text-center my-4">
                    <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); setShowPopup(true) }}>
                      Add Buyer
                    </button>

                    {!showUpdateButton && (
                      <button className="btn btn-primary mx-5" onClick={updateGSTDetails}>
                        Update GST
                      </button>
                    )}
                  </div>

                  <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowBookingModal(true);
                      }}
                    >
                      Book Tickets Now
                    </button>
                </form>

                <div className="">
                  <div className="mb-5 pb-5">
                    <h3 className="d-flex justify-content-center mt-3 text-decoration-underline">
                      Buyer List
                    </h3>
                    {loadingState ? (
                      <>
                        {memberListData.length > 0 && (
                          <>
                            <Table
                              responsive
                              className="table table-striped my-3 p-3 data-table"
                            >
                              <thead>
                                <tr>
                                  <th scope="col">Sr. No.</th>
                                  <th scope="col">Reg. No.</th>
                                  <th scope="col">Buyer Name</th>
                                  <th scope="col">Mobile No.</th>

                                  <th scope="col">Category</th>

                                  <th scope="col">Reg. Date </th>
                                  <th scope="col">Image</th>
                                  <th scope="col">ID Card</th>
                                  {/* <th scope="col">Book Ticket</th> */}
                                  <th scope="col">Edit Profile</th>
                                  <th scope="col">Edit Ticket</th>

                                  {/* <th scope="col">Info</th> */}
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {memberListData.map((data, idx) => {
                                  return (
                                    <tr>
                                      <th scope="row">{idx + 1}</th>

                                      <td>{data.barcode}</td>
                                      <td>{data.visitor_name}</td>
                                      <td>{data.mobile_number}</td>

                                      <td>{data.category === "exhibitor_vip" ? "Buyer" : data.category === "exhibitor_owner" ? "Exhibitor" : '_'}</td>
                                      <td>{moment(data.createdAt).format("MMM Do YY")}</td>

                                      <td>
                                        {data.photo ? (
                                          <>
                                            <div
                                              className={`img-size ${isHovered ? "image-hovered" : ""
                                                }`}
                                            >
                                              <img
                                                loading="lazy"
                                                className="img-size"
                                                src={`${process.env.REACT_APP_IMAGE_URL
                                                  }/photo/${data.photo
                                                  }?v=${moment().unix()}`}
                                                alt=""
                                                onerror="this.src='-'"
                                                onClick={() => {
                                                  setImageValue(
                                                    `${process.env.REACT_APP_IMAGE_URL
                                                    }/photo/${data.photo
                                                    }?v=${moment().unix()}`
                                                  );
                                                  setIsHovered(true);
                                                  setIsModalVisible(true);
                                                  setModalHeader(
                                                    `${data.visitor_name} Photo`
                                                  );
                                                }}
                                              />
                                            </div>
                                          </>
                                        ) : (
                                          <span>-</span>
                                        )}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          {data.id_proof_front ? (
                                            <div className="front-id-proof">
                                              <div className="main-img">
                                                <img
                                                  // className="img-size"
                                                  loading="lazy"
                                                  src={`${process.env.REACT_APP_IMAGE_URL
                                                    }/id_proof/${data.id_proof_front
                                                    }?v=${moment().unix()}`}
                                                  alt=""
                                                  onerror="this.src='-'"
                                                  className={`img-size ${isHovered ? "image-zoom" : ""
                                                    }`}
                                                  onClick={() => {
                                                    setImageValue(
                                                      `${process.env.REACT_APP_IMAGE_URL
                                                      }/id_proof/${data.id_proof_front
                                                      }?v=${moment().unix()}`
                                                    );
                                                    setIsHovered(true);
                                                    setIsModalVisible(true);
                                                    setModalHeader(
                                                      `${data.visitor_name} ID Proof Front`
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <span>-</span>
                                          )}
                                          {data.id_proof_back ? (
                                            <div>
                                              <img
                                                // className="img-size"
                                                loading="lazy"
                                                src={`${process.env.REACT_APP_IMAGE_URL
                                                  }/id_proof/${data.id_proof_back
                                                  }?v=${moment().unix()}`}
                                                alt=""
                                                onerror="this.src='-'"
                                                className={`img-size ${isHovered ? "image-zoom" : ""
                                                  }`}
                                                onClick={() => {
                                                  setImageValue(
                                                    `${process.env.REACT_APP_IMAGE_URL
                                                    }/id_proof/${data.id_proof_back
                                                    }?v=${moment().unix()}`
                                                  );
                                                  setIsHovered(true);
                                                  setIsModalVisible(true);
                                                  setModalHeader(
                                                    `${data.visitor_name} ID Proof Back`
                                                  );
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <span>-</span>
                                          )}
                                        </div>
                                      </td>

                                      <td>
                                        {!data.visitor_name ?
                                          <Button
                                            onClick={() => {
                                              setShowEditProfile(true);
                                              setEditProfileData(data);
                                              console.log('Data: ', data);
                                              setFormData({ ...formData, mobileNumber: data.mobile_number });
                                            }}
                                          >
                                            Update
                                          </Button> :
                                          <></>
                                        }
                                      </td>

                                      <td>
                                        <Button
                                          onClick={() => {
                                            setBookingEditData(data);
                                            setShowEditBookingModal(true);
                                            setEditFormData({
                                              travelMode: data.travelMode || '',
                                              travelReturnMode: data.travelReturnMode || '',
                                              departureCity: data.departureCity || '',
                                              returnCity: data.returnCity || '',
                                              departureDate: data.departureDate ? new Date(data.departureDate) : null,
                                              returnFlightDate: data.returnFlightDate ? new Date(data.returnFlightDate) : null,
                                              flightTrainName: data.flightTrainName || '',
                                              returnFlightName: data.returnFlightName || '',
                                              flightTrainNumber: data.flightTrainNumber || '',
                                              returnFlightNumber: data.returnFlightNumber || '',
                                              arrivalTimeGoa: data.arrivalTimeGoa || '',
                                              departure_time_from_source: data.departure_time_from_source || '',
                                              returnFlightDepartureTime: data.returnFlightDepartureTime || '',
                                            });
                                            setEditDepartureDate(data.departureDate);
                                            setEditReturnFlightDate(data.returnFlightDate);
                                            setEditVisitorData(data);
                                          }}
                                        >
                                          Edit
                                        </Button>
                                      </td>

                                      <td>
                                        <Button
                                          onClick={() => {
                                            setDeleteData(data)
                                            setShowDeleteModal(true);
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </>
                        )}
                        {memberListData.length <= 0 && (
                          <>
                            <div className="no-data-div content-height">
                              <h5 className="no-data-heading">No Record Found</h5>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="content-height d-flex justify-content-center align-items-center">
                        <Spinner animation="border" size="lg" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section >
      </div >
    </>
  )
}

export default BuyerRegistrationForm
